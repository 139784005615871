* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.focused {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.gen-message {
  color: #ba1ed5;
  font-weight: 600;
}

.grecaptcha-badge {
  visibility: hidden;
}
