.header {
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 3%;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 01);
}

.nav {
  width: 130px;
}
.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.nav li {
  margin: 0 0.5rem 0 0.5rem;
  font-size: 1.25rem;
}
.nav a {
  text-decoration: none;
  color: #88dfdf;
}
.nav a:hover,
.nav a:active,
.nav a.active {
  color: #e6fcfc;
}

/* FOR LOGO */
.title {
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  color: #fff;
  text-align: center;
  font-family: 'lato', sans-serif;
  font-weight: 800;
  letter-spacing: 10px;
  background-color: black;
}

@media (min-width: 600px) {
  .title {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 15px;
  }
}

.title img {
  max-width: 150px;
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  z-index: 99;
}
.title p {
  text-shadow: 2px 2px 2px white;
  -webkit-text-fill-color: transparent;
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
  display: flex;
}
.centered {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.reverse {
  flex-direction: row-reverse;
}
